import React from 'react';

class Faq extends React.Component {
    faqReplaceableStrings = [
        'FAQs: ',
        ' - FAQs',
        ': FAQs',
        'FAQ: ',
        'FAQs - ',
        'FAQs – ',
        ': FAQ',
        ' FAQs:',
        ' – FAQs',
        'Frequently Asked Questions: ',
        ': Frequently asked questions',
        ': Your questions answered',
        ':FAQs',
        ': Your questions asked',
        'FAQ\'s',
        ' FAQs',
        'FAQs',
        'FAQ',
        'Frequently asked questions about ',
        ' - Frequently asked questions',
        ' frequently asked questions'
    ];
    render() {
        let faqHeading;
        if (this.props.title && this.props.title !== "") {
            faqHeading = this.props.title;
            this.faqReplaceableStrings.forEach(string => {
                faqHeading = faqHeading.replace(string, '');
            });

            if (faqHeading === '') {
                faqHeading = null;
            } else {
                faqHeading += ': FAQs';
            }
        }

        if (!faqHeading) {
            faqHeading = `${this.props.articleTitle}: FAQs`;
        }

        return <div className={"my-2"}>
            <h2 className={"font-semibold leading-snug"}>{faqHeading}</h2>
            {this.props.data.map((data, key) => {
                return (
                    <div key={`faq-${key}`}>
                        <h3>
                            {data.title}
                        </h3>
                        <div dangerouslySetInnerHTML={{__html: data.text}}/>
                    </div>
                )
            })}
        </div>
    }
}

export default Faq
